<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group class="print">
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group class="print">
        <b-button
          :to="{ name: 'produit-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter produit</span>
        </b-button>
      </b-form-group>

      <b-form-group class="print">
        <b-button
          variant="gradient-primary"
          class="ml-1 d-inline-block"
          @click="orderStock"
        >
          <feather-icon icon="FilterIcon" />
          <span class="ml-1">Order Stock</span>
        </b-button>
      </b-form-group>
      <b-form-group class="print">
        <b-button
          variant="gradient-primary"
          class="ml-1 d-inline-block"
          @click="imprimer"
        >
          <feather-icon icon="PrinterIcon" />
          <span class="ml-1">Imprimer</span>
        </b-button>
      </b-form-group>
      <download-excel
        :data="produits"
        :fields="excel.fields"
        :name="excel.name"
        :title="excel.title"
        class="ml-1"
      >
        <b-button
          variant="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          Excel
        </b-button>
      </download-excel>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="produits"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            <p>{{ props.row.status }}</p>
          </b-badge>
        </span>

        <td
          v-else-if="props.column.field === 'quantity_total'"
          :style="{ backgroundColor: props.row.is_out_of_stock ? 'red' : 'transparent' , paddingLeft:'50px', paddingRight:'50px' ,color:props.row.is_out_of_stock ? 'white' : 'black' }"
        >
          {{ props.formattedRow[props.column.field] }}
        </td>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              v-if="props.row.status === 'À commander'"
              :id="`invoice-row-${props.row.id}-update-icon`"
              icon="FileTextIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="changeStatus(props.row)"
            />
            <b-tooltip
              title="Commander"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-update-icon`"
            />

            <feather-icon
              v-if="props.row.status === 'Commandé'"
              :id="`invoice-row-${props.row.id}-reset-icon`"
              icon="FileTextIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="resetStatus(props.row)"
            />
            <b-tooltip
              title="Annuler"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-reset-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="
                $router.push({
                  name: 'produit-edit',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
              @click="deleteProduit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import JsonExcel from 'vue-json-excel'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      load: 'false',
      pageLength: 20,
      dir: false,
      excel: {
        data: [],
        fields: {
          'Nom du produit': 'name',
          Reference: 'reference',
          'Stock Boutique': 'quantity',
          'Stock Labo': 'quantity_labo',
          'Stock Total': 'quantity_total',
          Status: 'status',
          Description: 'description',
          Lot: 'lot',
          'Prix de vente HT': 'sales_price',
          'Prix de vente TTC': 'sales_price_ttc',
          'Prix d\'achat HT': 'purchase_price',
          'Prix de gros HT': 'wholesale_price',
          'Prix de gros TTC': 'wholesale_price_ttc',
          'Prix dépôt HT': 'repository_price',
          'Prix dépôt TTC': 'repository_price_ttc',
        },
        name: 'products_export.xlsx',
        title: 'Liste des produits',
      },
      columns: [
        {
          label: 'Barcode',
          field: 'reference',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher barcode',
          },
        },
        {
          label: 'Nom du produit',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher produit',
          },
        },

        {
          label: 'S.B',
          field: 'quantity',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
          width: '120px',
        },
        {
          label: 'S.L',
          field: 'quantity_labo',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
          width: '120px',
        },
        {
          label: 'S Total',
          field: 'quantity_total',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher Stock',
          },
          width: '120px',
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      produits: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En stock' : 'light-success',
        'À commander' : 'light-danger',
        Commandé : 'light-warning',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getProduits()
  },
  methods: {
    imprimer() {
      window.print()
    },
    orderStock() {
      this.produits.sort((a, b) => b.quantity_total - a.quantity_total)
    },
    // get
    async getProduits() {
      this.load = 'true'
      const { data } = await axios.get('/products/stock/')
      this.produits = data
      this.load = 'false'
    },
    // delete
    deleteProduit(devid) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/products/delete/${devid}/`)
            .then(() => {
              this.load = 'false'
              this.getProduits()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Produit supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Produit Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // change status
    changeStatus(row) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux commander  ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.load = 'true'
        if (result.value) {
          await axios
            .put(
              `/products/update/${row.id}/`,
              {
                status: 'Commandé',
                name: row.name,
                reference: row.reference,
              },
            )
          this.load = 'false'
          this.getProduits()
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Produit commandé avec succés')
          }, 1000)
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Produit Non commandé :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // reset status
    resetStatus(row) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux annuler la commande  ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.load = 'true'
        if (result.value) {
          await axios
            .put(
              `/products/update/${row.id}/`,
              {
                status: 'À commandé',
                name: row.name,
                reference: row.reference,
              },
            )
          this.load = 'false'
          this.getProduits()
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Commande annulé avec succés')
          }, 1000)
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Produit Non commandé :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@media print {
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .print{
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
